@import "./../../base/variables";

.header-services {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  max-width: 1024px;
  position: fixed !important;
  top: 0;
  width: 100%;
  @media(min-width: 1400px) {
    width: 100%;
  }

  &.without-modal {
    .right-action {
      .icon-button {
        display: none;
      }
    }
  }

  .toolbar {
    align-items: center;
    display: flex;
    height: 60px;
    padding: 0 0 0 15px;
    white-space: nowrap;
  }

  .icon-button {
    color: #fff;
    padding: 5px;
  }

  .title {
    color: #fff;
    font-size: $size-menu;
    font-weight: 400;
    margin-left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      @media(min-width: 960px) {
        text-transform: capitalize;
      }
    }

    a:last-child {
      margin-right: 8px;
    }
  }

  .right-action {
    margin-left: auto;
    display: flex;

    .icon-button {
      color: #fff;
      position: relative;
      left: 0;
    }
  }

  .header-button {
    color: #fff;
    padding-right: 15px;
  }

  .search-bar {
    padding-left: 10px;

    .form-control {
      flex-grow: 1;
      justify-content: center;
    }

    .search {
      background-color: #fff;
      border-color: #fff;
      border-radius: 5px;
      height: 42px;

      &::placeholder {
        color: #B5B5B5;
        font-size: $size-menu;
      }

      input::-webkit-input-placeholder {
        line-height: normal !important;
      }

      .icon-button {
        color: #B5B5B5;
        padding: 0;
      }
    }

    .fieldset {
      border: none;
      max-height: 42px;
      top: 0;
    }

    .input {
      height: 42px;
      padding-bottom: 0;
      padding-left: 14px;
      padding-top: 0;
    }

    .cancel-btn {
      color: #fff;
      font-size: $size-button;
      padding-right: 0;
      text-transform: capitalize;
    }
  }
}