@import "./../base/variables";

.mobile-menu {
  display: none;
  @media (max-width: 960px) {
    display: none;
    position: absolute;
    bottom: 0;
    right: auto;
    background-color: #fff;
    bottom: 0;
    box-shadow: none;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1102;
  }

  .single {
    background-color: transparent;
    display: flex;
    height: 42px;
    justify-content: center;
    padding: 9px 0;
    min-height: 42px;
    min-width: 0;
    padding: 35px 0;
    width: 25%;
    float: left;

    svg {
      width: 26px;
      margin-bottom: 0;

      #dashboard path,
      #more,
      #service,
      .outline {
        fill: $color-primary;
      }
    }

    .main-label {
      display: block;
      float: none;
      font-size: x-small;
      position: absolute;
      bottom: 6px;
    }

    &.active {
      color: $color-primary;

      svg #dashboard path,
      svg #more,
      svg #service,
      svg .outline {
        fill: #202020;
      }

      svg .people {
        fill: #202020;
      }
    }

    &.dashboard {
      border-radius: 0;
      height: 38px;
      min-height: 38px;
      min-width: 38px;
      position: relative;
      width: 38px;

      img {
        bottom: 0;
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 0;
      }

      &.active {
        background-color: transparent;
      }
    }
  }

  .bottom-icons {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
  }
}
@media (max-width: 960px) {
  #intercom-container,
  .intercom-lightweight-app {
    display: none;
  }

  .impersonate {
    // .help-tab {
    //   display: none;
    // }

    .mobile-menu {
      .single {
        width: 33%;
      }
    }

    .main-tab {
      .single {
        width: 33%;
      }
    }
  }
}
