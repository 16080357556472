@import "./../../base/variables";

.confirmation-summary {
  @media(min-width: 960px) {
    .header-more {
      display: none;
    }

    .inner-content-wrapper {
      margin: 0 auto;
      padding-top: 40px;
      width: 850px;
    }

    .desktop-title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .next-step {
      margin-left: 15px;
      margin-top: 30px;
    }
  }

  .content {
    .title {
      align-items: center;
      border-bottom: 1px solid $color-border-light;
      color: #B5B5B5;
      display: flex;
      font-size: $size-text;
      height: 40px;
      justify-content: flex-start;
      padding: 0 20px;
      text-transform: uppercase;
    }
  }

  .time-interval {
    border-bottom: 1px solid $color-border-light;
    color: $color-text;
    font-size: $size-menu;
    margin-bottom: 50px;
    padding: 14px 20px;
  }

  .switch-control {
    align-items: center;
    border-bottom: 1px solid $color-border-light;
    display: flex !important;
    justify-content: space-between;
    padding: 0 20px;

    .sub-title {
      color: #B5B5B5;
      font-size: $size-text;
      text-transform: uppercase;
    }
  }

  .switch-on {
    display: flex;
    justify-content: center;
    margin-top: 65px;
    padding: 0 20px;
  }

  .switched-content {
    padding: 20px;

    .text-field {
      border-radius: 0;
      margin-bottom: 0;
      width: 100%;
    }

    .label {
      color: $color-form-label;
      font-size: $size-label;
    }

    .input-wrapper {
      background-color: $color-surface-light;

      &:before {
        border-color: $color-border;
      }
    }

    .input {
      color: $color-input-text;
      font-size: $size-text;
      padding: 23px 12px 10px;
    }

    .text {
      align-items: center;
      border-bottom: 1px solid $color-border-light;
      display: flex;
      justify-content: space-between;
      margin-top: 22px;

      .title {
        border: none;
        color: #B5B5B5;
        font-size: $size-text;
        padding-left: 20px;
        text-transform: uppercase;
      }

      .right-icons {
        .material-icons {
          color: $color-primary;
        }

        .icon-button {
          padding: 4px 10px;
        }
      }
    }

    .textfield-wrapper {
      padding: 5px;
    }

    .multiline-textfield {
      background-color: #F9F9F9;
      box-sizing: border-box;
      min-height: 150px;
      padding: 16px;
    }

    .form-control {
      color: $color-text;
      font-size: $size-menu;
    }

    .form-control::before {
      display: none;
    }

    .form-control::after {
      display: none;
    }
  }
}