@import "./../../base/variables";
@media(min-width: 1024px) {
  .services-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }
}
/*@media(min-width: 1141px) {
  .services-wrapper{
    left: -16px;
  }
}*/
.weekly-worship {
  .header-services {
    background-color: #DCDCDC;

    .icon-button {
      color: #343434;
    }

    .title {
      color: #343434;
      text-transform: none;
    }

    .right-action {
      padding-right: 10px;
    }
  }
}