@import "./../../base/variables";

.notifications {
  .single-switch {
    border-bottom: 1px solid #e1e1e1;
    color: $color-text;
    display: flex;
    margin-right: 0;
    padding: 12px 20px;
  }

  .section-title {
    border-bottom: 1px solid #e1e1e1;
    color: #b5b5b5;
    display: flex;
    font-size: $size-text;
    padding: 20px 0 5px 20px;
    text-transform: uppercase;
  }

  .label {
    width: 100%;
  }

  .label-with-icon {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
}