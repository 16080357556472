// color
$color-primary: #7bc807;
$color-secondary: #76e5c7;
$color-error: #b92d00;
$color-input-text: #343434;
$color-text: #343434;
$color-form-label: #707070;
$color-surface-dark: #cfcfcf;
$color-border-light: #e4e4e4;
$color-surface-light: #f9f9f9;
$color-border: #c5c5c5;
$border-soft-light: #e9e9e9;
$color-orange: #fbc02d;

// FONT FAMILY
$primary-font: "Poppins", sans-serif;

// FONT SIZE
$size-title: 26px;
$size-subtitle: 24px;
$size-menu: 16px;
$size-text: 14px;
$size-input-text: 16px;
$size-button: 14px;
$size-hint-text: 12px;
$size-label: 12px;

// padding
$form-padding: 32px;

// Spacing
$minimal-spacing: 10px;
$medium-spacing: 20px;
$large-spacing: 30px;
