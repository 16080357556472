@import "./../base/variables";

.bottom-modal {
  max-height: 100%;
  width: 100%;

  .modal-content {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    bottom: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    left: 0;
    padding: 20px 25px;
    position: fixed;
    right: 0;

    .content-single {
      color: $color-text;
      display: block;
      font-size: $size-menu;
      font-weight: 400;
      margin-bottom: 8px;
      text-transform: capitalize;
    }
  }

  .backdrop {
    background-color: #000;
    opacity: 0.8 !important;
  }
}