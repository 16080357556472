.flexer-col {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.flexer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flexer-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.TB {
  border: 1px solid red !important;
}

a {
  text-decoration: none !important;
  color: inherit;
}

/* .react-grid-HeaderRow div {
  transform: none !important;
} */

.react-grid-Viewport {
  height: 100% !important;
  overflow-x: auto;
}

.react-grid-Canvas {
  height: 90% !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.organization-filter {
  padding: 12px !important;
  font-size: 0.8rem !important;
}

@media (min-width: 365px) and (max-width: 1300px) {
  .react-grid-Canvas {
    height: 90% !important;
    overflow-x: auto !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

.primary-color {
  color: #7bc807;
}

.logs-container {
  padding-left: 245px;
  padding-right: 0px;
}

.logs-heading {
  font-weight: 400;
  font-size: 24px;
  margin-left: 15px;
}

.text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pending-row > div > div > div {
  background-color: yellow !important;
}

.number-modal {
  width: 500px;
}

.cursor-pointer {
  cursor: pointer;
}

.flexer-start {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
