@import "./../../base/variables";

.people-list {
  .header-people {
    background-color: #f6f6f6 !important;

    .title a {
      color: #272727;
    }

    .icon-button {
      color: #272727;
    }
  }
}

.single-person {
  align-items: center;
  border-bottom: 1px solid $color-border-light;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  text-decoration: none;

  &.disabled {
    border-bottom: 1px solid #777;
    opacity: 0.2;
    pointer-events: none;
  }

  .left-content {
    color: $color-text;
    display: flex;
    flex-direction: column;

    .name-position {
      display: flex;
    }

    .name {
      font-size: 15px;
      margin-right: 6px;
    }

    .position {
      color: #c5c5c5;
      font-size: 15px;
    }

    .number {
      color: #c5c5c5;
      font-size: 13px;
    }
  }

  .right-content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .icon {
      font-size: 28px;

      &.active {
        color: $color-primary;
      }

      &.absence {
        color: #eec214;
      }

      &.inactive {
        color: #ff5454;
      }
    }
  }

  .template-type {
    background-color: #d9d9d9;
    color: #fff;
    height: 24px;
    text-align: center;
    width: 24px;
  }

  .status {
    color: $color-border;
    font-size: $size-text;
    min-width: 50px;
    text-align: right;

    .icon {
      font-size: 28px;

      &.confirmed {
        color: $color-primary;
      }
    }
  }

  .log {
    .icon {
      align-items: center;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 16px;
      height: 23px;
      justify-content: center;
      width: 23px;

      &.send-icon {
        background-color: #b5b5b5;
      }

      &.tick-icon {
        background-color: $color-primary;
      }

      &.cross-icon {
        background-color: #ff5454;
      }

      &.help-icon {
        color: #fbc02d;
        font-size: 27px !important;
      }
    }
  }
}
