@import "./../../base/variables";

.one-day-service {
  .single-service {
    .template-type {
      display: none;
    }
  }
}
@media(min-width: 1024px) {
  .services-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }
}
/*@media(min-width: 1141px) {
  .services-wrapper{
    left: -16px;
  }
}*/