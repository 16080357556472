@import "./../../base/variables";

.logs-wrapper {
  background-color: #fff;
  bottom: 0;
  left: 0;
  max-height: calc(100% - 112px);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 70px;

  .single-service {
    align-items: center;
    border-bottom: 1px solid $color-border-light;
    display: flex;
    height: 64px;
    height: auto;
    justify-content: space-between;
    padding: 20px 10px;
    text-decoration: none;

    .left-content {
      color: $color-text;
      display: flex;
      margin-right: 10px;
      width: 75%;

      pre {
        font-family: Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        margin: 0;
        white-space: pre-wrap;
      }
    }

    .right-content {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .icon {
        font-size: 28px;

        &.active {
          color: $color-primary;
        }

        &.absence {
          color: #EEC214;
        }

        &.inactive {
          color: #FF5454;
        }
      }
    }

    .template-type {
      background-color: #D9D9D9;
      color: #fff;
      height: 24px;
      text-align: center;
      width: 24px;
    }

    .status {
      color: $color-border;
      font-size: $size-text;
      min-width: 50px;
      text-align: right;

      .icon {
        font-size: 28px;

        &.confirmed {
          color: $color-primary;
        }
      }
    }
  }

  .rc-swipeout-btn {
    height: 100%;
    text-transform: uppercase;

    &.active {
      display: none;
    }

    &.absence {
      background-color: #EEC214 !important;
      color: #fff;
      font-size: 13px;
    }

    &.inactive {
      background-color: #B5B5B5;
      color: #DCDCDC;
      font-size: 12px;
    }
  }

  .icon-div {
    display: flex;
    font-size: 12px;
    min-width: 75px;

    .detail {
      margin-left: 10px;
    }
  }
  @media (min-width: 960px) {
    .single-service {
      flex-direction: column;

      .left-content {
        flex-direction: column;
        margin-bottom: 6px;
        margin-right: 0;
        width: 95%;
      }

      .right-content {
        justify-content: flex-start;
        padding-left: 23px;
        width: 100%;
      }

      .icon-div {
        margin-bottom: 14px;
      }

      &.clicked {
        .left-content {
          flex-direction: row;

          pre {
            margin-left: 10px;
          }
        }
      }
    }
  }
  @media(max-width: 600px) {
    .single-service {
      flex-direction: column-reverse;
      padding: 20px;
    }

    .left-content {
      flex-direction: column;
      width: 100% !important;
    }

    .right-content {
      justify-content: flex-start !important;
      margin-bottom: 10px;
      width: 100%;
    }

    .icon-div {
      margin-bottom: 6px;
    }
  }
}
@media (min-width: 1024px) {
  .logs-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }
}

.desktop-design {
  .logs-wrapper {
    left: 65px;
    /*top: 15px;*/
    .desktop-title {
      margin-left: 15px;
    }
  }
}

.desktop-header {
  left: 60px;
  max-width: none;
}