@import "./../../base/variables";

.header-more {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  max-width: 1024px;
  position: fixed !important;
  top: 0;
  width: 100%;

  .toolbar {
    background-color: #202020;
    height: 60px;
    padding: 0 5px 0 15px;
  }

  .icon-button {
    color: #fff;
    padding: 0;
  }

  .title {
    color: #fff;
    font-size: $size-menu;
    font-weight: 400;
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
    }

    a:last-child {
      margin-right: 8px;
    }
  }

  .right-action {
    display: flex;
    margin-left: auto;

    .action-button {
      color: #fff;
    }

    .icon-button {
      color: #fff;
      left: 0;
      padding: 5px;
      position: relative;
    }
  }

  .search-bar {
    padding-left: 10px;
    padding-right: 5px;

    .form-control {
      flex-grow: 1;
      justify-content: center;
    }

    .search {
      background-color: #fff;
      border-color: #fff;
      border-radius: 5px;
      height: 42px;

      &::placeholder {
        color: #B5B5B5;
        font-size: $size-menu;
      }

      input::-webkit-input-placeholder {
        line-height: normal !important;
      }

      .icon-button {
        color: #B5B5B5;
        padding: 0;
      }
    }

    .fieldset {
      border: 0;
      max-height: 42px;
      top: 0;
    }

    .input {
      padding: 11px 14px 11px 0;
    }

    .cancel-btn {
      color: #fff;
      font-size: $size-button;
      padding-right: 10px;
      text-transform: capitalize;
    }
  }
}