@import "./../../base/variables";

.users-page {
  .sub-menu {
    bottom: 0;
    left: 60px;
    position: fixed;
    top: 0;
  }
}

.inner-content-wrapper {
  @media (min-width: 960px) {
    padding-left: 285px;
    padding-top: 40px;
  }
  @media (max-width: 960px) {
    margin-top: 80px;
  }

  .desktop-title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}

.react-grid-users-table {
  margin: 15px;

  .react-grid-Container {
    -webkit-overflow-scrolling: auto;
  }

  .react-grid-Grid {
    border: none !important;
    border-radius: 5px 5px 0 0;
    min-height: calc(100vh - 200px) !important;
  }

  .react-grid-Canvas {
    width: 100% !important;
  }

  .react-grid-Main {
    outline: none !important;
    // padding-left: 20px;
    // padding-right: 20px;
  }

  .react-grid-HeaderRow {
    background-color: #f2f2f2;
    border: none !important;
    height: 50px;
    overflow: hidden;

    div {
      align-items: center;
      color: #131313;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      height: 50px;
      padding: 0 0 0 8px;

      &:first-child {
        padding-left: 12px;
      }
    }
  }

  .react-grid-Row {
    border-bottom: 1px solid #f2f2f2;
    height: 50px !important;

    .react-grid-Cell {
      font-size: 13px;
      font-weight: 500;
      height: 50px !important;
      line-height: 1.3;
      padding-right: 0;

      &:nth-child(2) {
        padding-left: 20px;
      }

      p {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.3;
      }
    }

    &.yellow-row {
      .react-grid-Cell {
        background-color: rgb(211, 220, 6) !important;
      }
    }

    .react-grid-Cell__value {
      border-right: 1px solid #f2f2f2;
    }
  }
}

.total-credit {
  margin-right: 75px;
}

.add-credit-link {
  color: #a0d900;
  cursor: pointer;
}

.save-btn {
  font-weight: bold;
  background: #7bc807 !important;
  color: white !important;
}

.red-btn {
  color: rgb(217, 0, 0) !important;
}
