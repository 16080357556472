@import "./../base/variables";

.header-for-all {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  max-width: 1024px;
  position: fixed !important;
  top: 0;
  width: 100%;

  .toolbar {
    height: 60px;
    padding: 0 15px;
  }

  .icon-button {
    color: #fff;
    padding: 0;
  }

  .title {
    color: #fff;
    font-size: $size-menu;
    font-weight: 400;
    margin-left: 0;
  }

  .fieldset {
    border: none;
    max-height: 42px;
    top: 0;
  }

  .next-btn {
    color: #fff;
    font-size: $size-button;
    margin-left: auto;
    padding: 0;
    text-transform: uppercase;
  }
}