@import "./../../base/variables";

.more-main-wrapper {
  background-color: #f8f8f8;
  display: flex;
  flex-grow: 1;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 100%;

  .sub-menu {
    max-width: 100%;
    min-width: 100%;
    width: 100%;

    .menuitem-wrapper:last-child {
      bottom: 0;
      position: relative;
    }
  }
  @media(min-width: 960px) {
    width: 70%;

    .page-wrapper {
      bottom: 0;
      left: 292px;
      padding-top: 0 !important;
      position: fixed;
      right: 0;
      width: 70% !important;

      .header-more {
        left: 0;
        position: sticky !important;
        right: 0;
        top: 0;
      }

      .message-tabs {
        width: 70% !important;
      }
    }
  }
  @media(min-width: 1400px) {
    width: 100%;
  }
  @media(max-width: 960px) {
    background-color: #fff;
    margin-left: 0;
    overflow-y: auto;
  }
}

.more-main {
  overflow-y: auto;
  padding: 18px 26px;

  &.desktop {
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    left: 50px;
    max-width: 242px;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;

    .single {
      button {
        padding: 6px 0;
        word-break: break-all;
      }
    }

    .user-data {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }

    .pastorsline-logo {
      margin: 10px 24px;
      max-height: 50px;
      max-width: 100%;
    }

    .personal-info {
      justify-content: left;
      padding: 16px 24px;

      .avatar {
        margin-right: 15px;
      }

      .number {
        color: $color-text;
        font-size: $size-text;
        margin-bottom: 18px;

        .info {
          font-weight: 600;
        }
      }

      .credits {
        color: $color-text;
        font-size: $size-text;

        .info {
          font-weight: 600;
        }
      }
    }

    .navs {
      overflow-y: auto;
      padding: 0 24px;

      .single-nav {
        align-items: center;
        display: flex;
        padding-top: 17px;
        text-decoration: none;

        &.active .nav-icon {
          background-color: $color-primary;
        }
      }

      .nav-icon {
        align-items: center;
        background-color: $color-text;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-right: 16px;
        padding: 5px;

        .material-icons {
          color: #fff;
          font-size: 15px;
        }
      }

      .nav-label {
        color: $color-text;
        font-size: 12px;
      }
    }
  }

  .personal-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .avatar {
      border-radius: 50%;
      height: 50px !important;
      max-width: 50px;
      width: 100% !important;
    }

    .single {
      color: $color-text;
      font-size: $size-text;

      .title {
        color: $color-text;
        font-size: $size-text;
      }

      .info {
        font-weight: 600;
      }
    }
  }

  .single-card {
    border-radius: 6px;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
    height: 100%;
    padding: 22px 0 14px;
    width: 100%;

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .icon {
      background-color: $color-primary;
      border: none;
      border-radius: 50%;
      color: #fff;
      padding: 10px;
    }

    .icon.disabled {
      background-color: #ccc;
    }

    .label {
      color: $color-text;
      font-size: $size-label;
      margin-top: 16px;
      text-transform: capitalize;
      white-space: nowrap;
    }

    &.pastorsline-logo {
      background-color: #5517C1;
      padding: 43px 0;

      img {
        width: 100%;
      }
    }
  }
}