@import "./../../base/variables";

.planning-center {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media (min-width: 960px) {
    justify-content: space-between;
    &.authorise {
      margin-left: 60px;
    }
  }

  .pco {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .logo-wrapper {
      align-items: center;
      background-color: #2c5fd8;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    p {
      color: $color-text;
      font-size: $size-text;
      padding: 32px 0;
    }

    .btn {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .bitly {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .logo-wrapper {
      align-items: center;
      background-color: #eee;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    p {
      color: $color-text;
      font-size: $size-text;
      padding: 32px 0;
    }

    .btn {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .comming-soon {
    overflow: hidden;
    padding: 0 14px;
    @media (min-width: 960px) {
      padding: 0;

      .logos {
        padding-left: 20px;
      }
    }

    h3 {
      border-bottom: 1px solid $color-border-light;
      color: $color-text;
      font-size: $size-text;
      font-weight: 400;
      padding: 4px 12px;
      text-transform: uppercase;
      @media (max-width: 360px) {
        font-size: 13px;
      }
    }

    .logos {
      align-items: center;
      display: flex;

      img {
        max-width: 100%;
      }
    }
  }
}
