@import "./../../base/variables";

.message-tabs {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  max-width: 1024px;
  position: unset !important;
  right: auto !important;
  top: 56px !important;
  width: 100%;

  .indicator {
    background-color: #fff;
  }

  .tab-single {
    align-items: center;
    color: #fff;
    display: flex;
    opacity: 0.5;
    white-space: nowrap;
    @media (max-width: 360px) {
      font-size: 13px;
    }

    &.selected {
      color: #fff;
      opacity: 1;
    }
  }
}

.outer-wrapper {
  .select-all {
    align-items: center;
    border-bottom: 1px solid $color-border-light;
    display: flex;
    justify-content: left;
    margin: 20px 20px 0;

    .checkbox {
      padding-left: 0;
    }
  }

  .fab {
    bottom: 76px;
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.26));
    height: 45px;
    position: absolute;
    right: 20px;
    width: 45px;
    z-index: 1102;

    .material-icons {
      color: #fff;
      font-size: 38px;
    }
  }

  .disabled,
  .disabled:hover {
    background-color: #ccc;
  }
}

.remind-choose-people {
  .header-services {
    background-color: #2a2a2a;
  }

  .message-tabs {
    background-color: #343434;
  }
  @media (min-width: 960px) {
    padding-top: 40px;

    .header-services {
      display: none;
    }

    .people-tabs {
      margin: 0 auto;
      padding-top: 60px;
      width: 850px;
    }

    .desktop-title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .next-step {
      margin-left: 5px;
      margin-top: 30px;
    }
  }
}

.editor-class {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 0px 15px;
  background-color: white;
}

.editor-label {
  color: #7b7b7b;
  font-weight: 600;
}

.character-count-container {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;

  span {
    font-size: 14px;
  }

  strong {
    color: #7bc807;
    margin-top: 5px;
    cursor: pointer;
  }
}

.editor-inner-wrapper {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 15px;
  background-color: #fafafa;
  margin-top: 20px;
}

.wrapper-class {
  margin-top: 10px;
  display: flex;
  flex-direction: column-reverse;
}

.toolbar-class {
  position: relative !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;
  border: none !important;
  margin-top: 5px !important;
  background-color: #fafafa !important;
}
.change-toolbar-order :nth-child(2) {
  order: 2 !important;
}

.editor-toolbar-icons {
  padding: 0px 10px !important;
}

.code-icon {
  border-right: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
}

.preview-box-container {
  width: 35%;
  align-self: flex-start;
  margin-top: 15px;

  h6 {
    opacity: 0.5;
    margin-left: 15px;
  }
}

.preview-box-wrapper {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 15px;
  text-align: left;
  height: auto;
}

.merge-fields {
  font-size: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  margin-top: 5px;
  padding: 0 2px;
  cursor: pointer;
  margin-right: 8px;
}

.editor-btn {
  padding: 5px 15px !important;
  color: white !important;
  width: 140px;
}

.merge-field-wrapper {
  max-width: 700px;
}
