@import "./../base/variables";

.service-single {
  align-items: center;
  border-bottom: 1px solid $color-border-light;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 20px;

  .left-content {
    color: $color-text;
    display: flex;

    .material-icons {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}