@import "./../base/variables";

.profile-info-dropright {
  .paper {
    background: #fff;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    left: 60px !important;
    overflow: visible;
    padding-bottom: 5px;
    padding-left: 0;
    padding-top: 0;
    position: relative;
    width: 243px;

    &::before {
      background-color: #fff;
      bottom: 16px;
      content: "";
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
      height: 11px;
      left: -7px;
      position: absolute;
      transform: rotate(45deg);
      width: 11px;
    }

    .menuitem-wrapper {
      border-bottom: 1px solid #e2e2e2;
      padding: 10px 0;

      &:hover {
        background-color: transparent;
      }

      &:last-child {
        border-bottom: none;
      }

      &.name-title {
        background-color: #f8f8f8;
        padding-bottom: 15px;
        padding-left: 18px;
        padding-top: 15px;

        .avatar {
          border-radius: 50%;
          height: 30px !important;
          margin-right: 10px;
          width: 30px !important;
        }

        .name {
          color: #202020;
          font-size: 1rem;
          font-weight: 600;
          margin: 0;
        }

        .title {
          color: #565656;
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.number {
        background-color: #f8f8f8;
        padding-left: 18px;

        span {
          color: #202020;
          font-size: 14px;
          font-weight: 600;
        }

        &.addNumberLink {
          background-color: #eb5a46;
          span {
            color: #fff !important;
          }
        }
      }
    }

    .menuitem {
      background-color: #fff;
      display: flex;
      padding: 6px 0 6px 18px;

      &:hover {
        background-color: #f0f0f0;
      }

      .icon {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 12px;
        color: #000;

        svg {
          height: 14px;
          width: 14px;

          path {
            fill: #202020;
          }
        }
      }

      .text {
        color: #202020;
        font-size: 14px;
        font-weight: 500;
      }

      &.logout {
        width: 100%;

        .icon svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
