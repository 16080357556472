@import "./../../base/variables";

.pastorsline {
  width: 100%;

  .inner-content-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    text-align: center;
  }
  @media (min-width: 960px) {
    header {
      display: none;
    }
  }
  @media (max-width: 959px) {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 60px;
  }

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }
  @media (min-width: 767px) {
    form {
      margin: auto;
      width: 550px;
    }
  }

  .helper-text {
    margin-right: 0;
    text-align: right;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .imported {
    background-color: #fff;
    border: 1px solid #cccccc;
    margin-top: 20px;
    padding: 12px 22px;

    .pc-details {
      color: $color-text;
      font-size: 14px;
    }
  }

  .contained-primary {
    margin-top: 20px;

    .label {
      color: #fff;
    }
  }

  .paper {
    position: relative;

    .title {
      font-size: 18px;
      font-weight: 400;
      padding-left: 24px;
      padding-top: 15px;
      @media (max-width: 374px) {
        font-size: 17px;
        padding-left: 15px;
      }
    }

    .close-icon {
      cursor: pointer;
      font-size: 18px;
      position: absolute;
      right: 12px;
      top: 20px;
    }
  }
  .pl-number {
    margin-bottom: 35px;
  }
  .modal-numbers-wrapper .single-number {
    width: 31%;
  }
}

.create-acc {
  .pastorsline {
    width: 50%;
  }
}
