@import "./../base/variables";

.main-tab {
  @media(min-width: 960px) {
    display: none;
  }

  .container-wrapper {
    background-color: #202020;
    bottom: 0;
    box-shadow: none;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1102;

    .main-label {
      font-size: 10px;
      text-transform: capitalize;
    }

    .bottom-icons {
      display: none;
    }
    @media(min-width: 960px) {
      background-color: #202020;
      box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
      right: auto;
      top: 0;
      width: 60px;

      .flex-container {
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
      }

      .bottom-icons {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
      }

      .single-tab {
        background-color: transparent;
        border-radius: 50%;
        height: 42px;
        margin-bottom: 15px;
        max-width: 60px;
        min-height: 42px;
        min-width: 42px;
        width: 42px;

        svg {
          margin-bottom: 0;

          .outline {
            fill: $color-primary;
          }
        }

        &.dashboard {
          border-radius: 0;
          height: 38px;
          min-height: 38px;
          min-width: 38px;
          position: relative;
          width: 38px;

          img {
            bottom: 0;
            left: 0;
            max-width: 100%;
            position: absolute;
            top: 0;
          }

          &.selected {
            background-color: transparent;
          }
        }

        &.selected {
          background-color: $color-primary;

          svg .outline {
            fill: #202020;
          }

          svg .people {
            fill: #202020;
          }
        }

        .main-label {
          display: none;
        }
        /*&.more-tab {
          display: none;
        }*/
      }
    }
  }

  .tab-indicator {
    display: none;
  }

  .single-tab {
    min-height: 60px;
    height: 60px;
    padding: 0;

    svg {
      width: 25px;

      path {
        fill: #D9D9D9;
      }
    }

    .main-label {
      color: #D9D9D9;
    }

    &.selected {
      svg path {
        fill: $color-primary;
      }

      .main-label {
        color: $color-primary;
      }
    }

    &.dashboard {
      svg {
        margin-bottom: 0 !important;
      }
    }
    @media(max-width: 360px) {
      font-size: $size-label;

      &.dashboard {
        svg {
          height: 25px;
          width: 25px;
        }

        &.selected {
          background-color: transparent;
        }
      }
    }

    &.notification {
      position: relative;

      &::after {
        background-color: #fff;
        border: 2px solid #202020;
        border-radius: 50%;
        content: '';
        height: 6px;
        position: absolute;
        right: 12px;
        top: 11px;
        width: 6px;
      }
    }
  }

  .main-tab-content {
    bottom: 0;
    height: calc(100% - 59px);
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1101;
    @media(min-width: 960px) {
      background-color: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      display: flex;
      height: 100vh;
      left: 60px;
      margin: 0 auto;
    }

    .logs-wrapper,
    .page-wrapper,
    .services-wrapper {
      @media screen and (min-width: 960px) and (max-width: 1141px) {
        margin-left: auto !important;
      }
      @media(min-width: 960px) {
        margin: auto;
        transform: none;
        width: 100%;
      }
    }

    &.undefined {
      display: none;
    }

    &.dashboard-container {
      background: #fff;
      height: calc(100% - 78px);
      overflow-y: auto;
      @media(min-width: 960px) {
        height: 100vh;
      }
    }

    &.main-tab-more {
      @media(min-width: 960px) {
        box-shadow: none;
        display: flex;
        overflow: hidden;
        transform: none;
        width: 100%;
      }
    }
  }

  .disabled {
    color: #ddd !important;
    display: none;

    svg path {
      fill: #ddd !important;
    }
  }
}

.small-logo {
  width: 26px;
}