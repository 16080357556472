@import "./../base/variables";

.home-page {
  align-items: center;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 60px;
  text-align: center;

  .logo {
    margin-bottom: 110px;
    @media (max-width: 960px) {
      margin-bottom: 40%;
      margin-top: 0;

      .small-logo {
        width: 80px;
      }
    }
    @media (max-width: 600) {
      margin-bottom: 60%;
      margin-top: -40%;
    }
  }

  .contained-primary {
    margin-bottom: 20px;

    .label {
      font-size: $size-button;
    }
  }

  .text-primary:hover {
    background-color: transparent;
  }

  .text-primary .label {
    color: #067302;
    font-size: $size-button;
    font-weight: 600;
  }
}

.login-page {
  height: 100%;
  margin: 0 auto;
  padding: 75px 25px;
  @media (min-width: 960px) {
    max-width: 325px;
    padding: 50px 25px 25px;
  }
  @media (max-width: 960px) {
    padding-top: 0;

    .logo-wrapper {
      flex-direction: column;
      margin-bottom: 30px;

      h3 {
        display: block !important;
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    .small-logo {
      width: 80px;
    }

    .contained-primary {
      margin-bottom: 25px !important;
    }
  }

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .helper-text {
    margin-right: 0;
    text-align: right;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .contained-primary {
    border-radius: 4px;
    margin-bottom: 90px;
    margin-top: 10px;

    .label {
      font-size: $size-button;
    }
  }

  .text-primary {
    justify-content: flex-end;
    white-space: nowrap;
    width: 100%;

    &:hover {
      background-color: transparent;
    }

    .label {
      color: #067302 !important;
      font-size: $size-button;
      font-weight: 600;

      .light {
        font-weight: 400;
      }

      .forgot-pw {
        margin: 20px 0;
      }
    }
  }
  @media (min-width: 960px) {
    .contained-primary {
      float: right;
      margin-bottom: 50px;
      margin-top: 10px;
      width: 100px;

      .label {
        font-size: $size-button;
      }
    }
  }
}

.sweet-loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success {
  background-color: #acd66c;
  color: #fff;
  padding: 4px 10px;

  &.center {
    text-align: center;
  }
  &.mb {
    margin-bottom: 10px;
  }
}
.error {
  background-color: #e24e4e;
  color: #fff !important;
  padding: 4px 10px;

  h5 {
    color: #fff !important;
  }

  &.center {
    text-align: center;
  }
  &.mb {
    margin-bottom: 10px;
  }
}
.success {
  background-color: #acd66c;
  color: #fff !important;
  padding: 4px 10px;

  h5 {
    color: #fff !important;
  }
}
