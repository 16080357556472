@import "./../base/variables";

.person-single {
  align-items: center;
  display: flex;
  padding: 14px 14px 14px 30px;

  .name {
    color: $color-text;
    font-size: $size-menu;
  }

  .service-day {
    color: #B5B5B5;
    font-size: $size-label;
  }

  .checkbox {
    padding: 0;
  }
}