.app-wrapper,
body,
html {
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

.btn {
  border-radius: 0;
  box-shadow: none;

  &.contained-primary {
    padding: 8px 16px;

    .label {
      color: #fff;
    }
  }

  &.text-primary:hover {
    .label {
      text-shadow: 0.5px 1px #7bc807;
    }
  }

  .label {
    text-transform: none;
  }
}

.app-wrapper {
  overflow: hidden;

  .logo-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 70px;

    h3 {
      color: #067302;
      display: none;
      font-size: 26px;
      font-weight: 500;
      margin-left: 20px;
    }

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      width: 100%;
    }
  }

  .auth-wrapper {
    width: 100%;
    @media (min-width: 960px) {
      background-color: #fff;
      height: auto;
      height: 100vh;
      overflow: hidden;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;

      .logo-wrapper {
        margin-bottom: -15px;

        h3 {
          display: inline-block;
        }
      }

      .logo {
        display: flex;
        height: 33px;
        justify-content: center;
        width: 33px !important;
      }

      &.with-scroll {
        overflow: hidden;
      }
    }

    .flash-message {
      align-items: center;
      background-color: #d1f5d0;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 5px 10px;

      p {
        font-size: 12px;
      }

      .close-icon {
        cursor: pointer;
        font-size: 15px;
      }
    }

    &.with-scroll {
      overflow: initial;
    }
  }

  .auth-banner {
    display: none;
    @media (min-width: 960px) {
      background: linear-gradient(to top left, #02b414 0%, #bcff01 100%);
      display: block;
      overflow: hidden;
      position: relative;
      width: 50%;

      img {
        position: absolute;
        top: -20px;
        width: 100%;
      }
    }
  }

  .page-wrapper {
    display: block;
    overflow-y: auto;
    padding-top: 60px;
    width: 100%;
    @media (max-width: 960px) {
      margin-bottom: 60px;

      &.create-acc {
        max-height: calc(100% - 60px);

        .header-for-all {
          background-color: #202020;
        }
      }
    }
    @media (min-width: 960px) {
      background-color: #fff;
      display: flex;
      height: 100vh;
      padding-top: 0;
      width: 100%;

      &.create-acc .header-for-all {
        display: none;
      }

      &.create-acc {
        display: flex;
        overflow: hidden;
      }

      &.login {
        display: flex;
        overflow: hidden;
      }
    }
  }
  @media (max-width: 768px) {
    overflow: inherit;
    overflow-y: auto !important;
  }
}

.more-main-wrapper {
  .page-wrapper {
    @media (min-width: 960px) {
      display: flex;
      flex-direction: column;
      padding-top: 0;

      .header-more {
        left: 0;
        position: sticky !important;
        right: 0;
        top: 0;
      }

      .inner-content-wrapper {
        flex-grow: 1;
        max-width: 1024px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

.tab-container {
  /*padding-top: 50px !important;*/
  margin-bottom: 70px;

  .tab-content {
    margin: 20px;
  }
}

.sweet-loading {
  background: rgba(0, 0, 0, 0.1);
  // height: 2000px;
  // padding-top: 175px;
  position: absolute;
  width: 100%;
  z-index: 10000;
}

.flash-message {
  align-items: center;
  background-color: #d1f5d0;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 5px 10px;

  &.mb {
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
  }

  .close-icon {
    cursor: pointer;
    font-size: 15px;

    &.relative {
      right: auto;
      background: none;
      position: relative;
      top: auto;
    }
  }
}
