@import "./../../base/variables";

.users-table {
  width: calc(100% - 330px);

  .confirm {
    background-color: #fff;
  }

  .confirm:hover {
    background-color: #fafafa;
  }
}

.numbers-page {
  padding-right: 20px;
  @media (max-width: 768px) {
    padding-right: 0;
  }

  .hidden {
    display: none;
  }

  .sub-menu {
    bottom: 0;
    display: none;
    position: fixed;
    top: 0;
  }

  .mobile-view {
    display: block;

    .card-single {
      border-radius: 6px;
      box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
      margin-bottom: 30px;
      padding: 15px 20px;

      .top-section {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      .number {
        font-weight: 600;
      }

      .icon-button {
        padding: 0;
      }

      .main-section {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .default-tag {
        background-color: $color-primary;
        border-radius: 5px;
        color: #fff;
        font-weight: 600;
        padding: 1px 7px;
        text-transform: uppercase;
      }
    }
  }
}

.numbers-page .desktop-view {
  display: none;
  margin-left: 42px;
  margin-top: 23px;
  padding-left: 225px;
  width: 100%;

  .title-section {
    display: flex;
    justify-content: space-between;

    .btn {
      border-radius: 3px;
      height: 40px;
    }
  }

  .desktop-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 25px;
    margin-left: -18px;
  }
}

.dropdown-more {
  background: #fcfcfc;
  border: 1px solid #efefef;
  border-radius: 6px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
}

.number-page-desktop,
.user-page-desktop {
  border-bottom: 1px solid #ddd;
  box-shadow: none !important;
  margin-left: -18px;
  overflow: hidden;

  .table-head {
    background-color: #f2f2f2;
    color: #131313;
    font-size: 14px;
    font-weight: 500;
    height: 40px;

    th {
      padding: 0 0 0 18px;

      &:last-child {
        background-color: #fff;
        position: sticky;
        right: 0;
      }
    }
  }

  .table-body-for-numbers tr {
    height: 50px;

    td {
      border-bottom: 1px solid #f2f2f2;
      color: #131313;
      font-size: 16px;
      padding: 0 0 0 18px;

      &:first-child {
        width: 22%;
        @media (max-width: 1100px) {
          width: 30%;
        }
      }

      &:last-child {
        background-color: #fff;
        position: sticky;
        right: 0;
      }

      &.app-column {
        .default {
          /*display: none;*/
        }
      }
    }

    .app-column {
      .default {
        background-color: $color-primary;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        height: 20px;
        margin-left: 100px;
        text-align: center;
        width: 76px;
      }
    }
  }
}

.select-number-wrapper {
  height: 100%;

  .selected-number {
    align-items: center;
    border-radius: 6px;
    color: $color-text;
    display: flex;
    font-size: $size-input-text;
    height: 60px;
    justify-content: flex-start;
    padding: 0 20px;
    width: 100%;

    input {
      border: 0;
      right: 2px;
    }
  }

  .title {
    border-bottom: 1px solid $color-border-light;
    color: #b5b5b5;
    font-size: #b5b5b5;
    font-weight: 400;
    margin-top: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .change-number {
    padding: 20px;
  }

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .number-btn {
    align-items: center;
    border: 1px solid $color-border;
    border-radius: 0;
    color: $color-form-label;
    display: flex;
    font-size: $size-text;
    height: 50px;
    justify-content: center;
    padding: 0;
    width: 100%;

    &:active {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }

    &:focus {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .text-primary {
    float: right;

    .label {
      color: #067302;
      font-size: $size-label;
      font-weight: 500;
    }

    .material-icons {
      font-size: 18px;
      margin-right: 3px;
    }
  }
}

.number-dialog {
  .title {
    border-bottom: 1px solid $color-border-light;
    color: #b5b5b5;
    font-size: $size-text;
    font-weight: 400;
    margin-top: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .dialog-content {
    padding: 0;
  }

  .paper {
    border-radius: 0;
    box-shadow: none;
    margin: 25px;
  }

  .backdrop {
    background-color: #000;
    opacity: 0.8 !important;
  }

  .number-content {
    color: $color-text;
    font-size: 24px;
    padding: 20px;
  }

  .action-btn {
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    padding: 8px 16px;
  }
}
@media (min-width: 960px) {
  .app-wrapper {
    .page-wrapper.with-scroll {
      height: 100vh !important;
    }
  }

  .numbers-page {
    position: relative;

    .header-more {
      display: none;
    }

    .sub-menu {
      display: block;
      flex-grow: 1;
    }

    .desktop-view {
      display: block;
      flex-grow: 1;
    }

    .mobile-view {
      display: none;
    }
  }
}

.fab {
  bottom: 76px !important;
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.26));
  height: 45px !important;
  position: absolute !important;
  right: 20px;
  width: 45px !important;
  z-index: 1102;

  .material-icons {
    color: #fff;
    font-size: 38px;
  }
}

.addNumberLink {
  background-color: #eb5a46 !important;
  span {
    color: #fff !important;
  }
}
