@import "./../base/variables";

.template {
  border-bottom: 1px solid #e1e1e1;
  padding: 20px;

  .title-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    svg {
      min-width: 20px;
    }
  }

  .title {
    color: $color-text;
    font-size: $size-menu;
  }

  .detail {
    color: #b5b5b5;
    font-size: $size-text;
  }

  .lock {
    margin-top: -30px;
  }
}
