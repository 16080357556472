@import "./../base/variables";

.custom-dropdown {
  .dropdown-item {
    max-width: 250px;
    width: 100%;

    .title {
      color: $color-text;
      font-size: $size-text;
      margin-left: 10px;
    }

    &.border-bottom {
      border-bottom: 1px solid $color-border-light;
    }

    .custom-switch {
      margin-left: -12px;
    }
  }
}