@import "./../base/variables";

.create-acc .main-menu {
  z-index: 99;
}

.create-acc .registration-menu {
  align-items: center;
  background-color: #343434;
  display: flex;
  height: 40px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;

  .darkbtn {
    background-color: transparent;
    border-radius: 0;
    border-right: 1px solid #f0f0f0;
    margin: 0 auto;
    width: 100%;

    &:last-child {
      border-right: none;
    }
  }
}

.create-acc .welcome {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-left: 60px;
  padding-top: 40px;
  width: 90%;
  @media (max-width: 600px) {
    padding-left: 0;
  }

  .welcome-card {
    height: auto;
    max-height: 100%;
    padding: 0;
    width: 100%;
  }

  .card-content {
    display: flex;
    padding: 12px;
    @media (max-width: 600px) {
      flex-direction: column-reverse;

      .banner img {
        position: relative !important;
      }
    }
  }

  .content {
    color: #343434;
    padding: 20px 14px 28px 19px;

    h3 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 14px;
    }
    @media (max-width: 360px) {
      text-align: center;

      h3 {
        font-size: 18px;
      }

      .contained-primary {
        margin-top: 8px;
      }
    }
  }

  .banner {
    position: relative;

    img {
      bottom: -2px;
      max-height: 128%;
      max-width: 100%;
      position: absolute;
      right: -2px;
    }
  }
}

.create-account-wrapper {
  height: 100%;
  margin: 0 auto;
  padding: 30px 25px;
  @media (min-width: 960px) {
    max-width: 325px;
    padding: 35px 25px 25px;

    &.steps .btn.contained-primary {
      float: right;
      max-width: 100px;
    }
  }
  @media (max-width: 960px) {
    .nextbtn {
      display: none;
    }

    .logo-wrapper {
      display: none;
    }
  }

  &.steps .flash-message {
    align-items: center;
    background-color: #d1f5d0;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 5px 10px;

    p {
      font-size: 12px;
    }

    .close-icon {
      cursor: pointer;
      font-size: 15px;
    }
  }

  .text-field,
  .date-picker,
  .MuiInput-underline {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
    span {
      font-size: $size-label;
    }
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }

    &.phone {
      height: 50px;
      margin-top: 12px;
      width: 100%;
      position: relative;

      &::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        pointer-events: none;
      }

      &::after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0;
        border-bottom: 2px solid #7bc807;
        pointer-events: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.13);

        &:before {
          border-bottom: 1px solid rgba(0, 0, 0, 0.87);
        }
      }

      &.error {
        &:before {
          border-bottom-color: #d10202;
          border-bottom-width: 2px;
        }
      }

      .form-control {
        background-color: transparent;
      }

      input {
        border: none;
        height: 100%;
        width: 100%;
      }

      .flag-dropdown {
        background-color: transparent;
        border: none;

        &:hover {
          background-color: transparent;
        }

        .selected-flag {
          background-color: transparent;
        }

        &.open .selected-flag {
          background-color: transparent;
        }
      }

      .react-tel-input {
        padding-left: 10px;
        height: 100%;
      }
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .contained-primary {
    margin-bottom: 90px;
    margin-top: 10px;

    .label {
      font-size: $size-button;
    }
  }

  .text-primary {
    justify-content: flex-end;
    margin-top: 40px;
    white-space: nowrap;
    width: 100%;

    &:hover {
      background-color: transparent;
    }

    .label {
      color: #067302;
      font-size: $size-button;
      font-weight: 600;

      .light {
        font-weight: 400;
      }
    }
  }
}

.choose-number-wrapper {
  margin: 0 auto;
  padding: 30px 24px 22px;
  @media (min-width: 960px) {
    max-width: 340px;
    padding: 30px 20px 22px;
    width: 100%;

    &.steps .btn.contained-primary {
      float: right;
      max-width: 100px;
    }

    .change-number-notice {
      color: #343434;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .for-small-device {
      display: none;
    }

    .for-large-device {
      display: flex;
      flex-direction: column;

      .contained-primary {
        border-radius: 4px;
      }
    }
  }
  @media (max-width: 960px) {
    .for-small-device {
      display: block;
    }

    .for-large-device {
      display: none;
    }

    .nextbtn {
      display: none;
    }
  }

  .selected-number {
    align-items: center;
    border: 1px solid $border-soft-light;
    border-radius: 6px;
    color: $color-text;
    display: flex;
    font-size: $size-input-text;
    height: 60px;
    justify-content: center;
    margin-bottom: 35px;
    width: 100%;
    @media (min-width: 960px) {
      margin-bottom: 5px;
    }
    input {
      border: 0;
      right: 2px;
    }
  }

  .title {
    border-bottom: 1px solid $color-border-light;
    color: $color-text;
    font-size: $size-text;
    font-weight: 400;
    padding-bottom: 4px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .text-field,
  .date-picker,
  .MuiInput-underline {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .radio-group {
    padding: 5px;
    width: 100%;
  }

  .radio-buttons {
    display: flex;
    justify-content: space-between;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    ::placeholder {
      font-size: 12px;
    }

    &:before {
      border-color: $color-border;
    }
  }

  .numbers-wrapper {
    .label {
      display: block;
      text-align: center;
    }
  }

  .input,
  .MuiSelect-select.MuiSelect-select {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
    text-align: left;
  }

  label#expiry_date-label {
    color: $color-input-text;
    font-size: $size-text;
    padding-left: 12px;
    text-align: left;
  }

  .text-primary {
    float: right;

    .label {
      color: #067302;
      font-size: $size-label;
      font-weight: 500;
    }

    .material-icons {
      font-size: 18px;
      margin-right: 3px;
    }
  }

  .pastorsline-number-select {
    margin-top: 115px;
  }

  .pastorsline-btn {
    align-items: center;
    background-color: #5517c1;
    border-radius: 6px;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-top: 20px;
    width: 102% !important;

    &:hover {
      background-color: #5517c1;
    }

    .label {
      color: #fff;
      font-size: 14px;
      text-transform: capitalize;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}

.create-acc {
  .header-for-all {
    height: 60px;
  }

  .verify-email {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    margin-top: -60px;

    .page-title {
      color: #343434;
      font-size: 24px;
      padding: 20px;
      text-align: center;
    }

    .verify-content {
      color: #707070;
      font-size: 15px;
      padding: 20px;
    }

    .contained-primary {
      border-radius: 4px;
    }

    .outlined-btn {
      border-radius: 4px;
      padding: 8px 16px !important;
    }

    .text-field {
      margin: 30px 0;
    }

    .sub-title {
      color: #343434;
      font-size: 18px;
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding: 20px;
      text-align: center;
      width: 100%;
    }
  }
}

.sweet-loading {
  // height: 2000px;
  // left: 0px;
  // top: 60px;
}

#recaptcha {
  margin-top: 20px;
}

.div-error {
  color: #d10202;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.66;
}
