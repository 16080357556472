@import "./../../base/variables";

.service-tabs {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  max-width: 1024px;
  position: fixed !important;
  right: auto !important;
  top: 56px !important;
  width: 100%;
  background-color: #222 !important;

  .indicator {
    background-color: #fff;
  }

  .tab-single {
    align-items: center;
    color: #fff;
    display: flex;
    opacity: 0.5;
    white-space: nowrap;
    min-height: 48px;

    .icon {
      margin-bottom: 0;
    }

    .on-select {
      display: none;
    }

    .wrapper {
      flex-direction: row;
    }

    &.selected {
      color: #fff;
      opacity: 1;

      .folder {
        display: none;
      }

      .on-select {
        display: inline-block;
      }
    }

    .material-icons {
      margin-right: 8px;
    }
  }

  .label-with-icon {
    display: flex;
  }
}

.icon {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 16px !important;
  height: 23px !important;
  justify-content: center;
  left: 7px;
  top: 7px;
  width: 23px !important;

  &.send-icon {
    background-color: #B5B5B5;
  }

  &.tick-icon {
    background-color: $color-primary;
  }

  &.cross-icon {
    background-color: #FF5454;
  }

  &.help-icon {
    color: #FBC02D;
    font-size: 27px !important;
  }
}

.services {
  .mobile-design {
    margin-top: 45px;
  }
}