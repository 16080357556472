.value-button {
  display: inline-block;
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 11px 0;
  background: #eee;
  font-size: 1.2rem;
}

.value-button:hover {
  cursor: pointer;
}

.credit-input {
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 60px;
  height: 40px;
}

.credit-input::-webkit-inner-spin-button,
.credit-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
