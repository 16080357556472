@import "./../base/variables";

.main-menu {
  display: none;
  @media (min-width: 960px) {
    align-items: center;
    background-color: #202020;
    bottom: 0;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    left: 0;
    padding-top: 20px;
    position: relative;
    height: 100%;
    right: auto;
    top: 0;
    width: 60px;
    z-index: 2;
  }

  .single {
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    height: 42px;
    justify-content: center;
    margin-bottom: 15px;
    max-width: 60px;
    min-height: 42px;
    min-width: 42px;
    padding: 0;
    width: 42px;

    .admin {
      color: #7bc807;
    }

    svg {
      margin-bottom: 0;

      .outline {
        fill: $color-primary;
      }
    }

    &:hover {
      background-color: #444;
    }

    &.active {
      background-color: $color-primary;
      .admin {
        color: #000;
      }

      svg .outline {
        fill: #202020;
      }

      svg .people {
        fill: #202020;
      }
    }

    &.dashboard {
      border-radius: 50%;
      height: 54px;
      min-height: 38px;
      min-width: 38px;
      position: relative;
      width: 54px;

      img {
        bottom: 0;
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 0;
      }

      &.active {
        background-color: transparent;
      }
    }
  }

  .bottom-icons {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
  }
}
