@import "./../../base/variables";

.single-template-content {
  border-bottom: 1px solid $color-border-light;
  padding: 14px 20px;

  .item-title {
    color: $color-text;
    font-size: $size-menu;
    margin-bottom: 10px;
  }

  .item-detail {
    color: #B5B5B5;
    font-size: $size-menu;
  }
}

.services-templates {
  .single-section {
    margin-top: 50px;

    &:first-child {
      margin-top: 10px;
    }

    .title {
      border-bottom: 1px solid $color-border-light;
      color: #B5B5B5;
      font-size: $size-text;
      padding: 8px 20px;
      text-transform: uppercase;
    }
  }
}

.switch-right {
  position: absolute !important;
  right: 20px;
  z-index: 1000 !important;
}