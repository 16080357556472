@import "./../../base/variables";

.single-service {
  align-items: center;
  border-bottom: 1px solid $color-border-light;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 20px;
  text-decoration: none;

  .left-content {
    color: $color-text;
    display: flex;
  }

  .right-content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .icon {
      font-size: 28px;

      &.active {
        color: $color-primary;
      }

      &.absence {
        color: #EEC214;
      }

      &.inactive {
        color: #FF5454;
      }
    }
  }

  .template-type {
    background-color: #D9D9D9;
    color: #fff;
    height: 24px;
    text-align: center;
    width: 24px;
    display: none;
  }

  .status {
    color: $color-border;
    font-size: $size-text;
    min-width: 14px;
    text-align: right;

    .icon {
      font-size: 28px;

      &.confirmed {
        color: $color-primary;
      }
    }
  }
}

.services-wrapper {
  background-color: #fff;
  bottom: 72px;
  left: 0;
  max-height: calc(100% - 112px);
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 70px;

  .rc-swipeout-btn {
    height: 100%;
    text-transform: uppercase;

    &.active {
      display: none;
    }

    &.absence {
      background-color: #EEC214 !important;
      color: #fff;
      font-size: 13px;
    }

    &.inactive {
      background-color: #B5B5B5;
      color: #DCDCDC;
      font-size: 12px;
    }
  }
}