@import "./../../base/variables";

.create-schedule {
  @media(min-width: 960px) {
    .header-more {
      display: none;
    }

    .inner-content-wrapper {
      margin: 0 auto;
      padding-top: 40px;
      width: 850px;
    }

    .desktop-title {
      font-size: 20px;
      margin-bottom: 10px;
      margin-left: 20px;
    }
  }

  .textfield-wrapper {
    margin-bottom: 30px;
    padding: 20px;
  }

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .label {
    color: $color-text;
    font-size: $size-menu;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .confirmation {
    margin-bottom: 65px;

    .title {
      border-bottom: 1px solid $color-border-light;
      color: #B5B5B5;
      font-size: $size-text;
      padding: 8px 20px;
      text-transform: uppercase;
    }
  }

  .add-followup {
    border-color: $color-primary;
    color: $color-primary;
    font-size: $size-text;
    height: 40px;
    max-width: 170px;
    padding: 5px 10px;
    text-align: center;
    width: 100%;
  }

  .add {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .switch-control {
    align-items: center;
    border-bottom: 1px solid $color-border-light;
    display: flex !important;
    justify-content: space-between;
    padding: 0 20px;

    .sub-title {
      color: #B5B5B5;
      font-size: $size-text;
      text-transform: uppercase;
    }
  }

  .switch-on {
    display: flex;
    justify-content: center;
    margin-top: 65px;
    padding: 0 20px;
  }

  .switch-off {
    color: #B5B5B5;
    font-size: 14px;
    margin-top: 12px;
    padding: 0 20px;
  }
}