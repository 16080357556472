@import "./../base/variables";

.numbers-wrapper {
  .number-btn {
    margin: 0;
    position: relative;
    width: 100%;

    .checked {
      & + .number {
        background-color: $color-primary;
        border-color: $color-primary;
        color: #fff;

        span {
          color: #fff;
        }
      }
    }

    .number {
      align-items: center;
      border: 1px solid $color-border;
      border-radius: 0;
      color: $color-form-label;
      display: flex;
      font-size: $size-button;
      height: 50px;
      justify-content: center;
      width: 100%;
    }

    .radio {
      display: none;
    }
  }
}

.modal-numbers-wrapper {
  .single-number {
    width: 20%;
  }

  .number-btn {
    margin: 0;
    position: relative;
    width: 100%;

    .checked {
      & + .number {
        background-color: $color-primary;
        border-color: $color-primary;
        color: #fff;

        span {
          color: #fff;
        }
      }
    }

    .number {
      align-items: center;
      border: 1px solid $color-border;
      border-radius: 0;
      color: $color-form-label;
      display: flex;
      font-size: $size-button;
      height: 50px;
      justify-content: center;
      width: 100%;

      span {
        display: block;
        text-align: center;

        .label {
          font-size: 9px;
        }
      }
    }

    .radio {
      display: none;
    }
  }

  .text-primary {
    float: right;

    .label {
      color: #067302 !important;
      font-size: $size-label;
      font-weight: 500;
      text-transform: capitalize;
    }

    .material-icons {
      font-size: 18px;
      margin-right: 3px;
    }
  }
}
