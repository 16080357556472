@import "./../../base/variables";

.select-number-wrapper {
  height: 100%;

  .selected-number {
    align-items: center;
    border-radius: 6px;
    color: $color-text;
    display: flex;
    font-size: $size-input-text;
    height: 60px;
    justify-content: flex-start;
    padding: 0 20px;
    width: 100%;
  }

  .title {
    border-bottom: 1px solid $color-border-light;
    color: #b5b5b5;
    font-size: #b5b5b5;
    font-weight: 400;
    margin-top: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .change-number {
    padding: 20px;
  }

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .number-btn {
    align-items: center;
    border: 1px solid $color-border;
    border-radius: 0;
    color: $color-form-label;
    display: flex;
    font-size: $size-text;
    height: 50px;
    justify-content: center;
    padding: 0;
    width: 100%;

    &:active {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }

    &:focus {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .text-primary {
    float: right;

    .label {
      color: #067302;
      font-size: $size-label;
      font-weight: 500;
    }

    .material-icons {
      font-size: 18px;
      margin-right: 3px;
    }
  }
}

.number-dialog {
  .title {
    border-bottom: 1px solid $color-border-light;
    color: #b5b5b5;
    font-size: $size-text;
    font-weight: 400;
    margin-top: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .dialog-content {
    padding: 0;
  }

  .paper {
    border-radius: 0;
    box-shadow: none;
    margin: 25px;
  }

  .backdrop {
    background-color: #000;
    opacity: 0.8 !important;
  }

  .number-content {
    color: $color-text;
    font-size: 24px;
    padding: 20px;
  }

  .action-btn {
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    padding: 8px 16px;
  }
}
@media (min-width: 960px) {
  .app-wrapper {
    .schedules.page-wrapper.with-scroll {
      height: 100vh !important;
      margin-top: 0;
      transform: none;
      padding-top: 0;
      overflow: hidden;
    }
  }
  .schedule-page-wrapper {
    margin-top: 80px;
    width: 100%;
  }

  .table-header {
    th {
      padding-top: 0px;
      padding-left: 0px;
      padding-bottom: 5px;
      font-weight: 600;
      font-size: 16px;
      color: #666666;
    }
  }

  .table-body {
    td {
      padding-left: 0px;
      &:first-child {
        width: 5% !important;
      }
    }
  }
}

.fab {
  bottom: 76px;
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.26));
  height: 45px !important;
  position: absolute !important;
  right: 20px;
  width: 45px !important;
  z-index: 1102;

  .material-icons {
    color: #fff;
    font-size: 38px;
  }
}

.icon.action-icon {
  font-size: 22px !important;
  opacity: 1 !important;
}

.text-center {
  text-align: center !important;
}

a.MuiTypography-colorPrimary {
  color: #000;
}

// .icon {
//   margin: auto auto 10px;
// }

.accepted {
  color: $color-primary;
}

.unconfirmed {
  opacity: 0.5;
}

.declined {
  color: #ff5454;
}

.accepted,
.declined,
.unconfirmed {
  margin: auto;
  width: 100px;
}

ul.MuiList-root.MuiList-padding {
  margin-bottom: 7px;
  margin-top: 7px;
}

li.MuiListItem-root.pull-right {
  position: absolute;
  right: 0;
  top: 27px;
  width: 135px;
}

li .text-center {
  margin: auto;
}

.schedule-templates {
  .desktop-design {
    .schedule-list {
      &.MuiList-padding {
        margin-right: 60px;
      }
    }
  }
}

.schedules {
  @media (min-width: 960px) {
    background-color: #fff;
    padding: 40px;

    .header-services {
      display: none;
    }

    .desktop-title {
      margin-top: 40px;
    }
  }
}

.schedule-heading {
  color: #666666;
  font-weight: 600 !important;
  margin-left: 70px !important;
}

.tick-icon {
  background-color: $color-primary;
  font-size: 20px !important;
}

.take-action {
  margin-left: 25px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  color: $color-primary;
  opacity: 1 !important;
}

.action-btn {
  width: 100px !important;
  height: 35px !important;
  margin-right: 10px !important;
}

.accept {
  background-color: $color-primary !important;
  color: white !important;
  .accepted-label {
    color: white !important;
  }
}

.decline {
  background-color: #ff6201 !important;
  color: white !important;

  .decline-label {
    color: white !important;
  }
}

.opacity-low {
  td span {
    color: gray;
  }
}

.mobile-page-wrapper {
  height: 100%;
  overflow-x: hidden;
  margin-top: 80px;
}

.opacity-low-mobile {
  li {
    color: gray;
  }
}
