@import "./../base/variables";

.after-auth-one {
  .steps-wrapper {
    padding: 36px 24px;

    .btn {
      text-align: left;
      width: 50%;

      .label {
        font-size: $size-input-text;
        justify-content: flex-start;
      }
    }

    .btn-active {
      border-bottom: 4px solid $color-primary;
      padding-bottom: 2px;

      .label {
        font-weight: 500;
      }
    }

    .btn-disabled {
      border-bottom: 2px solid $color-border;
      padding-bottom: 0;
    }
  }

  .content {
    padding: 0 24px;

    .label {
      color: $color-text;
    }
  }
}

.after-auth-two {
  .steps-wrapper {
    padding: 36px 24px;

    .btn {
      text-align: left;
      width: 50%;

      .label {
        font-size: $size-input-text;
        justify-content: flex-start;
      }
    }

    .btn-active {
      border-bottom: 4px solid $color-primary;
      padding-bottom: 2px;

      .label {
        font-weight: 500;
      }
    }

    .btn-disabled {
      border-bottom: 2px solid $color-border;
      padding-bottom: 0;
    }
  }

  .service-title {
    border-bottom: 1px solid $color-border-light;
    color: $color-border;
    font-size: $size-text;
    padding: 4px 20px;
    text-transform: uppercase;
  }

  .upcoming-services {
    padding-top: 70px;
  }
}